<template>
	<div class="card rounded-0" style="max-width: 100%">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Calendar Events</div>
			<v-spacer></v-spacer>
		</v-layout>
		<v-layout class="">
			<div class="pa-3 dash-calendar-event">
				<v-date-picker
					v-model="date2"
					:event-color="(date) => (date[9] % 2 ? 'red' : 'green')"
					:events="functionEvents"
					prev-icon="mdi-skip-previous"
					next-icon="mdi-skip-next"
					color="blue darken-4"
					class="border"
					:picker-date.sync="currentMonth"
				></v-date-picker>
			</div>
			<v-flex class="pa-3">
				<div class="my-auto fw-600 h2 pb-1 border-bottom">{{ minDate }}</div>
				<div class="" style="height: 320px; overflow: auto">
					<div class="d-flex border-bottom py-2" v-for="(row, index) in activityData" :key="index">
						<div class="pa-2">
							<v-icon color="grey lighten-1" size="30">mdi-calendar</v-icon>
						</div>
						<div class="">
							<p class="mb-1 fw-600 mt-3">{{ row.description }}</p>
							<!--  <p class="mb-0 text-muted">Due by <span class="primary--text">Chris Brown</span></p> -->
						</div>
					</div>
				</div>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import moment from "moment-timezone";
import { filter } from "lodash";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
	name: "CalendarEvents",
	data() {
		return {
			activityData: [],
			currentMonth: null,
			arrayEvents: null,
			date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
		};
	},
	watch: {
		pickerDate(newval) {
			return newval;
		},
		currentMonth(newval) {
			this.getActivity();
			return newval;
		},
	},
	methods: {
		getActivity() {
			ApiService.setHeader();

			ApiService.query("dashboard-activity", { month: this.currentMonth }).then(({ data }) => {
				this.activityData = data.all_activity_data;
				this.totalCount = data.total_activity;
				this.checkFilterdata();
			});
		},
		functionEvents(date) {
			if (this.activityData) {
				let filterData = filter(this.activityData, function (row) {
					return row.description.includes("checked-out");
				});
				const [, , day] = date.split("-");
				if (filterData.includes(parseInt(day))) return ["red"];
				return false;
			}
		},

		checkFilterdata() {},
	},
	mounted() {
		this.getActivity();
		this.arrayEvents = [...Array(6)].map(() => {
			const day = Math.floor(Math.random() * 30);
			const d = new Date();
			d.setDate(day);
			return d.toISOString().substr(0, 10);
		});
	},
	computed: {
		minDate() {
			return moment(this.currentMonth).format("MMMM YYYY");
		},
	},
};
</script>
