<template>
	<v-container fluid class="dashboard-main pt-2 pb-2">
		<v-row>
			<v-col sm="12" md="12">
				<v-row class="dashboard-top-button">
					<v-col cols="2" md="2">
						<div class="card shadow gradient-color">
							<div class="card-body p-5">
								<div class="d-flex justify-space-between no-gutters align-items-center">
									<div class="mr-2">
										<div class="text-xs font-weight-bold text-white text-uppercase mb-1">Total Asset</div>
										<div class="h2 mb-0 font-weight-500 text-white">{{ totalAsset }}</div>
									</div>
									<div class="icon_box blue lighten-3">
										<i class="fas fa-calendar fa-2x theme--light blue--text"></i>
									</div>
								</div>
							</div>
						</div>
					</v-col>
					<v-col cols="2" md="2">
						<div class="card shadow gradient-color2">
							<div class="card-body p-5">
								<div class="d-flex justify-space-between no-gutters align-items-center">
									<div class="mr-2">
										<div class="text-xs font-weight-bold text-white text-uppercase mb-1">
											Total Asset Stock
										</div>
										<div class="h2 mb-0 font-weight-500 text-white">{{ totalAssetStock }}</div>
									</div>
									<div class="icon_box success lighten-3">
										<i class="fas fa-calendar fa-2x theme--light success--text"></i>
									</div>
								</div>
							</div>
						</div>
					</v-col>
					<v-col cols="2" md="2">
						<div class="card shadow gradient-color3">
							<div class="card-body p-5">
								<div class="d-flex justify-space-between no-gutters align-items-center">
									<div class="mr-2">
										<div class="text-xs font-weight-bold text-white text-uppercase mb-1">
											Total Perishables
										</div>
										<div class="h2 mb-0 font-weight-500 text-white">{{ totalPerishables }}</div>
									</div>
									<div class="icon_box orange lighten-3">
										<i class="fas fa-calendar fa-2x theme--light orange--text"></i>
									</div>
								</div>
							</div>
						</div>
					</v-col>
					<v-col cols="2" md="2">
						<div class="card shadow gradient-color4">
							<div class="card-body p-5">
								<div class="d-flex justify-space-between no-gutters align-items-center">
									<div class="mr-2">
										<div class="text-xs font-weight-bold text-white text-uppercase mb-1">Total Customer</div>
										<div class="h2 mb-0 font-weight-500 text-white">{{ totalCustomer }}</div>
									</div>
									<div class="icon_box indigo lighten-3">
										<i class="fas fa-calendar fa-2x theme--light indigo--text"></i>
									</div>
								</div>
							</div>
						</div>
					</v-col>
					<v-col cols="2" md="2">
						<div class="card shadow gradient-color">
							<div class="card-body p-5">
								<div class="d-flex justify-space-between no-gutters align-items-center">
									<div class="mr-2">
										<div class="text-xs font-weight-bold text-white text-uppercase mb-1">Total Supplier</div>
										<div class="h2 mb-0 font-weight-500 text-white">{{ totalSupplier }}</div>
									</div>
									<div class="icon_box blue lighten-3">
										<i class="fas fa-calendar fa-2x theme--light blue--text"></i>
									</div>
								</div>
							</div>
						</div>
					</v-col>
					<v-col cols="2" md="2">
						<div class="card shadow gradient-color2">
							<div class="card-body p-5">
								<div class="d-flex justify-space-between no-gutters align-items-center">
									<div class="mr-2">
										<div class="text-xs font-weight-bold text-white text-uppercase mb-1">Total Project</div>
										<div class="h2 mb-0 font-weight-500 text-white">{{ totalProject }}</div>
									</div>
									<div class="icon_box success lighten-3">
										<i class="fas fa-calendar fa-2x theme--light success--text"></i>
									</div>
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-col>
			<v-col md="4" lg="4">
				<div class="project-status-dashboard border h-100">
					<v-layout class="px-4 py-2 border-bottom">
						<div class="my-auto font-level-3-bold fw-600">Assets By Category</div>
					</v-layout>
					<div class="px-4 py-3">
						<v-row>
							<v-col md="12" cols="12" v-for="(row, index) in categorydata" :key="index">
								<div class="d-flex justify-content-between">
									<label style="font-size: 16px">{{ row.name }}</label>
									<label :class="`${colors[index]}--text`" style="font-size: 30px">{{
										row.total_asset
									}}</label>
								</div>
								<v-progress-linear
									:value="row.total_asset"
									:color="colors[index]"
									height="14"
									style="border-radius: 5px"
								>
								</v-progress-linear>
							</v-col>
						</v-row>
					</div>
				</div>
			</v-col>
			<v-col md="4" lg="4">
				<AssetBySupplier></AssetBySupplier>
			</v-col>
			<v-col md="4" lg="4">
				<OverAllStats></OverAllStats>
			</v-col>
			<v-col md="12" lg="6">
				<CalenderEvent></CalenderEvent>
			</v-col>
			<v-col cols="12" md="6" class="py-3">
				<Revenue></Revenue>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="12" v-if="false">
				<v-layout class="dashboard-top-button">
					<v-flex>
						<div class="card shadow gradient-color">
							<div class="card-body p-5">
								<div class="d-flex justify-space-between no-gutters align-items-center">
									<div class="mr-2">
										<div class="text-xs font-weight-bold text-white text-uppercase mb-1">Total Asset</div>
										<div class="h2 mb-0 font-weight-500 text-white">{{ totalAsset }}</div>
									</div>
									<div class="icon_box blue lighten-3">
										<i class="fas fa-calendar fa-2x theme--light blue--text"></i>
									</div>
								</div>
							</div>
						</div>
					</v-flex>
					<v-flex class="mx-4">
						<div class="card shadow gradient-color2">
							<div class="card-body p-5">
								<div class="d-flex justify-space-between no-gutters align-items-center">
									<div class="mr-2">
										<div class="text-xs font-weight-bold text-white text-uppercase mb-1">
											Total Asset Stock
										</div>
										<div class="h2 mb-0 font-weight-500 text-white">{{ totalAssetStock }}</div>
									</div>
									<div class="icon_box success lighten-3">
										<i class="fas fa-calendar fa-2x theme--light success--text"></i>
									</div>
								</div>
							</div>
						</div>
					</v-flex>
					<v-flex>
						<div class="card shadow gradient-color3">
							<div class="card-body p-5">
								<div class="d-flex justify-space-between no-gutters align-items-center">
									<div class="mr-2">
										<div class="text-xs font-weight-bold text-white text-uppercase mb-1">
											Total Perishables
										</div>
										<div class="h2 mb-0 font-weight-500 text-white">{{ totalPerishables }}</div>
									</div>
									<div class="icon_box orange lighten-3">
										<i class="fas fa-calendar fa-2x theme--light orange--text"></i>
									</div>
								</div>
							</div>
						</div>
					</v-flex>
					<v-flex class="ml-4">
						<div class="card shadow gradient-color4">
							<div class="card-body p-5">
								<div class="d-flex justify-space-between no-gutters align-items-center">
									<div class="mr-2">
										<div class="text-xs font-weight-bold text-white text-uppercase mb-1">Total Customer</div>
										<div class="h2 mb-0 font-weight-500 text-white">{{ totalCustomer }}</div>
									</div>
									<div class="icon_box indigo lighten-3">
										<i class="fas fa-calendar fa-2x theme--light indigo--text"></i>
									</div>
								</div>
							</div>
						</div>
					</v-flex>
				</v-layout>
			</v-col>
			<!-- <v-col cols="12" class="px-0">
				<Viewdetails></Viewdetails>
			</v-col> -->
			<v-col cols="12" lg="4" md="6" v-if="false">
				<template>
					<div class="project-status-dashboard shadow">
						<v-layout class="px-4 py-2 border-bottom">
							<div class="my-auto font-level-3-bold fw-600">Assets By Category</div>
						</v-layout>
						<div class="px-4 py-3">
							<v-row>
								<v-col md="6" cols="12" v-for="(row, index) in categorydata" :key="index">
									<div class="d-flex justify-content-between">
										<label style="font-size: 16px">{{ row.name }}</label>
										<label :class="`${colors[index]}--text`" style="font-size: 30px">{{
											row.total_asset
										}}</label>
									</div>
									<v-progress-linear
										:value="row.total_asset"
										:color="colors[index]"
										height="14"
										style="border-radius: 5px"
									>
									</v-progress-linear>
								</v-col>
							</v-row>
						</div>
					</div>
				</template>
			</v-col>
			<!-- <v-col cols="12" md="6" lg="4">
				<AssetByCategory></AssetByCategory>
			</v-col> -->
			<v-col cols="12" md="6" lg="4" v-if="false">
				<AssetBySupplier></AssetBySupplier>
			</v-col>
			<v-col cols="12" md="6" lg="4" v-if="false">
				<OverAllStats></OverAllStats>
			</v-col>
			<v-col cols="6" md="6" v-if="false">
				<Revenue></Revenue>
			</v-col>
			<v-col cols="6" md="6" v-if="false">
				<CalenderEvent></CalenderEvent>
			</v-col>
			<v-col cols="12" md="12" lg="6">
				<TopAsset></TopAsset>
			</v-col>
			<v-col cols="12" md="12" lg="6">
				<TopAssetStock></TopAssetStock>
			</v-col>
			<v-col cols="12" md="12" lg="6">
				<CheckOut></CheckOut>
			</v-col>
			<v-col cols="12" md="12" lg="6">
				<CheckInListing></CheckInListing>
			</v-col>
			<!-- <v-col cols="12" md="12" lg="6">
				<ServiceDueListing></ServiceDueListing>
			</v-col> -->
			<v-col cols="12" md="12" lg="12">
				<ProjectListing></ProjectListing>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import Revenue from "@/view/pages/components/Revenue";
import TopAsset from "@/view/pages/components/TopAsset.vue";
import TopAssetStock from "@/view/pages/components/TopAssetStock.vue";
//import AssetByCategory from "@/view/components/AssetByCategory.vue";
import AssetBySupplier from "@/view/components/AssetBySupplier.vue";
import OverAllStats from "@/view/pages/components/OverAllStats.vue";
import CalenderEvent from "@/view/components/CalenderEvent.vue";
import CheckOut from "@/view/components/CheckOut.vue";
import CheckInListing from "@/view/components/CheckInListing.vue";
//import ServiceDueListing from "@/view/components/ServiceDueListing.vue";
import ProjectListing from "@/view/components/ProjectListing.vue";
import ApiService from "@/core/services/api.service";
export default {
	name: "dashboard",
	data: () => ({
		categorydata: [],
		total_category: 0,
		totalAsset: 0,
		totalProject: 0,
		totalSupplier: 0,
		totalAssetStock: 0,
		totalPerishables: 0,
		totalCustomer: 0,
		colors: ["red", "green", "cyan", "yellow", "blue-grey"],
	}),
	methods: {
		createCustomer() {
			this.$router.push({ name: "customer-create" });
		},
		createAsset() {
			this.$router.push({ name: "asset-create" });
		},
		createAssetStock() {
			this.$router.push({ name: "asset-stock-create" });
		},
		createPerishable() {
			this.$router.push({ name: "inventory-create" });
		},
		getAllAssets() {
			ApiService.setHeader();
			ApiService.get("dashboard-all-assets").then(({ data }) => {
				this.totalAsset = data.totalAsset;
				this.totalAssetStock = data.totalAssetStock;
				this.totalPerishables = data.totalPerishables;
				this.totalCustomer = data.totalCustomer;
				this.totalSupplier = data.totalSupplier;
				this.totalProject = data.totalProject;
			});
		},
		getGroups() {
			ApiService.setHeader();
			ApiService.get("dashboard").then(({ data }) => {
				this.categorydata = data.asset_by_category;
				this.total_category = data.total_category;
			});
		},
	},

	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
		this.getGroups();
		this.getAllAssets();
	},
	components: {
		Revenue,
		TopAsset,
		TopAssetStock,
		//AssetByCategory,
		AssetBySupplier,
		OverAllStats,
		CalenderEvent,
		CheckOut,
		CheckInListing,
		//	ServiceDueListing,
		ProjectListing,
	},
};
</script>
